import SolanaLogo from './svgs/solanaLogoMark.svg'
import ZoraLogo from './logos/logoZora.png'
import BraveLogo from './logos/logoBrave.png'
import EthLogo from './logos/ethLogo.png'
import BaseLogo from './logos/baseLogo.png'
import OpMainnetLogo from './logos/opMainnetLogo.webp'
import EVMLogo from './logos/evmLogo.png'
import HamLogo from './logos/hamLogo.png'
import X_Logo from './logos/X_logo.png'
import StoryIPLogo from './logos/storyIPLogo.jpg'

const arbitrumLogo = 'https://icons.llamao.fi/icons/chains/rsz_arbitrum.jpg'
const degenLogo = 'https://basescan.org/token/images/degentips_32.png'
const polygonLogo = 'https://icons.llamao.fi/icons/chains/rsz_polygon.jpg'

export { SolanaLogo, EVMLogo, ZoraLogo, BraveLogo, EthLogo, BaseLogo, OpMainnetLogo, arbitrumLogo, degenLogo, HamLogo, X_Logo, polygonLogo, StoryIPLogo }
