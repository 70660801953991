import React from 'react'

const EditIconCreate = ({ width = 32, height = 32, stroke = '#1C274C', strokeWidth = 1.5 }) => {
	return (
		<svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				opacity="0.5"
				d="M29.3333 14V16C29.3333 22.2853 29.3333 25.4281 27.3807 27.3807C25.4281 29.3333 22.2853 29.3333 16 29.3333C9.71459 29.3333 6.5719 29.3333 4.61928 27.3807C2.66666 25.4281 2.66666 22.2853 2.66666 16C2.66666 9.7146 2.66666 6.57191 4.61928 4.6193C6.5719 2.66667 9.71459 2.66667 16 2.66667H18"
				stroke={stroke}
				strokeWidth={strokeWidth}
				strokeLinecap="round"
			/>
			<path
				d="M23.0679 3.74165L22.2027 4.60674L14.2496 12.5599C13.7109 13.0986 13.4416 13.3679 13.2099 13.6649C12.9366 14.0152 12.7024 14.3943 12.5112 14.7953C12.3492 15.1353 12.2288 15.4967 11.9878 16.2195L11.2166 18.5333L10.7175 20.0305C10.5989 20.3863 10.6915 20.7783 10.9566 21.0435C11.2217 21.3085 11.6138 21.4011 11.9694 21.2825L13.4667 20.7835L15.7805 20.0121C16.5033 19.7712 16.8647 19.6508 17.2047 19.4888C17.6057 19.2976 17.9848 19.0633 18.3351 18.7901C18.6321 18.5584 18.9015 18.2891 19.4401 17.7504L27.3932 9.79726L28.2584 8.93218C29.6916 7.49886 29.6916 5.17498 28.2584 3.74165C26.8251 2.30833 24.5012 2.30833 23.0679 3.74165Z"
				stroke={stroke}
				strokeWidth={strokeWidth}
			/>
			<path
				opacity="0.5"
				d="M22.2029 4.60678C22.2029 4.60678 22.3111 6.44509 23.9332 8.06713C25.5552 9.68917 27.3935 9.7973 27.3935 9.7973M13.4669 20.7835L11.2168 18.5333"
				stroke={stroke}
				strokeWidth={strokeWidth}
			/>
		</svg>
	)
}

export default EditIconCreate
